



























import { useState, useActions } from '@u3u/vue-hooks'
import { defineComponent, onBeforeMount } from '@vue/composition-api'

import storage from '@/composables/storage'
import { isDashboardEnabled } from '@/composables/ui'
import { report } from '@/composables/reporter'

export default defineComponent({
  name: 'login',
  components: {},
  props: {},

  setup(_props, ctx) {
    const { isLoading, data, error, token } = useState('user', ['isLoading', 'data', 'error', 'hasUser', 'token'])
    const { FETCH_USER, UPDATE_USER } = useActions('user', ['FETCH_USER', 'UPDATE_USER'])

    const redirect = () => {
      let redirect: string
      const isDashBoardEnabled = isDashboardEnabled()

      if (isDashBoardEnabled) {
        redirect = ctx.root.$route.params.redirect || 'Dashboard'
      } else {
        redirect = ctx.root.$route.params.redirect || 'DocumentsSign'
      }

      delete ctx.root.$route.query.u
      delete ctx.root.$route.query.code
      delete ctx.root.$route.query.state

      ctx.root.$router.push({
        name: redirect,
        query: ctx.root.$route.query,
        params: ctx.root.$route.params,
      })
    }

    const updateUsersList = () => {
      if (token.value) {
        const localData = storage.data
        const userExists = localData.value.users.some(user => user.token === token.value)

        if (!userExists && (data.value.firstname, data.value.lastname)) {
          const user = {
            firstname: data.value.firstname,
            lastname: data.value.lastname,
            token: token.value,
            loginToken: ctx.root.$route.query.token as string | null,
          }

          localData.value.users.push(user)
          storage.set(localData.value)
        }
      }
    }

    const redirectOnError = () => {
      ctx.root.$router.push({
        name: 'Home',
        query: { ...ctx.root.$route.query, error: 'login-error' },
        params: ctx.root.$route.params,
      })
    }

    const connectUser = async () => {
      const { code, state, correlationId } = ctx.root.$route.query

      if (token.value) {
        try {
          await UPDATE_USER()
          updateUsersList()
          redirect()
        } catch (error) {
          redirectOnError()
          ctx.root.$logger.error('UPDATE_USER', error)
        }
      } else if (correlationId || code || state) {
        const service = correlationId ? 'keysign' : 'itsme'
        try {
          await FETCH_USER({ service, code, state, correlationId })
          updateUsersList()
          redirect()
        } catch (error) {
          redirectOnError()
          ctx.root.$logger.error(error)
        }
      } else {
        report(new Error('Missing login data'), {
          title: 'Login error',
          description: 'Could not login a user because of missing data',
          eIDToken: token.value,
          itsMeCode: code,
          itsMeState: state,
        })
        ctx.root.$logger.error('Missing login data')
        ctx.root.$router.push({
          name: 'Home',
          query: { ...ctx.root.$route.query, error: 'login-data' },
          params: ctx.root.$route.params,
        })
      }
    }

    onBeforeMount(() => {
      connectUser()
    })

    return {
      error,
      isLoading,
      user: data,
    }
  },
})
